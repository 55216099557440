import revive_payload_client_GVn6EQfCjh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pXHR5hbmtw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pnlMuBqLP1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hbdOnetTMo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_fAKSi9tEjm from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_nQsR7njl4a from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_vLeEGaBDV2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jCbTiXzyH6 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@p_e0c7bada5b9a3fe50d683072bbd59223/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_T9pkhjmFsY from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.4.31_vue@3.4.31__magicast@0.3.4_rollup@4.18.0_vue@3.4.31/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_rtxE5goJp9 from "/opt/build/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.35_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.13_gr_fece04e5cfb5e34007a5e5f506b8bddf/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_IbFnldTJAV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding_980966ac449002ee384fd46bda623717/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_auth_pq8n4g3Sus from "/opt/build/repo/plugins/01.auth.js";
import gsap_client_Z6XKCRtOCT from "/opt/build/repo/plugins/gsap.client.js";
import pdfmake_o0ig8POQiq from "/opt/build/repo/plugins/pdfmake.js";
import presentation_nz8rlOU3yT from "/opt/build/repo/plugins/presentation.js";
import sentry_PZVGm6LH3G from "/opt/build/repo/plugins/sentry.js";
export default [
  revive_payload_client_GVn6EQfCjh,
  unhead_pXHR5hbmtw,
  router_pnlMuBqLP1,
  payload_client_hbdOnetTMo,
  check_outdated_build_client_fAKSi9tEjm,
  plugin_vue3_nQsR7njl4a,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vLeEGaBDV2,
  plugin_jCbTiXzyH6,
  plugin_T9pkhjmFsY,
  plugin_rtxE5goJp9,
  chunk_reload_client_IbFnldTJAV,
  _01_auth_pq8n4g3Sus,
  gsap_client_Z6XKCRtOCT,
  pdfmake_o0ig8POQiq,
  presentation_nz8rlOU3yT,
  sentry_PZVGm6LH3G
]